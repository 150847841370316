<template>
  <data-table
    :headers="headers"
    :items="items"
    :last-page="serverLastPage"
    :loading="bLocalLoading"
    :options="pagination"
    :total="serverItemsLength"
    btn-action-item-key-id="id"
    btn-action-path="companies"
    hide-btn-action-delete
    hide-btn-action-view
    @delete="deleteItem"
    @update:sort-by="onSortBy"
    @update:sort-desc="onSortDesc"
    @update:options="onPagination"
    @update:page="onPage"
  >
    <template #top>
      <companies-form
        :key="$route.params.id"
        v-model="obItem"
        :open.sync="displayForm"
      />
    </template>

    <template #[`body.prepend`]="{ headers }">
      <company-filters-row :tr-headers="headers" />
    </template>

    <template #[`item.name`]="{ item }">
      <v-btn
        :class="{ 'font-weight-bold': item.id === company.id }"
        :to="{ name: 'companies.update', params: { id: item.id } }"
        color="primary"
        text
      >
        {{ item.firm.name }}
      </v-btn>
    </template>

    <template #[`item.active`]="{ item }">
      <active-icon :active="item.active" />
    </template>

    <template #[`item.doc_type`]="{ item }">
      {{ $t(`doctype.${item.firm.doc_type}`) }}
    </template>

    <template #[`item.doc_id`]="{ item }">
      <doc-id-viewer
        v-if="item.firm.doc_id && item.firm.doc_type"
        :type="item.firm.doc_type"
        :value="item.firm.doc_id"
      />
    </template>

    <template #[`item.actions`]="{ item }">
      <module-actions :value="item.id" cannot-delete>
        <template #append-items>
          <v-list-item
            :class="{ 'error--text': item.is_empty }"
            :disabled="!item.is_empty && !isLocal"
            @click="deleteItem(item.id)"
          >
            <v-list-item-icon>
              <icon-trash />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="$t('remove')" />
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            :class="{
              'blue--text': item.id !== company.id,
              'green--text': item.id === company.id,
            }"
            :disabled="item.id === company.id"
            @click="setCompany(item)"
          >
            <v-list-item-icon>
              <icon-checkbox v-if="item.id == company.id" size="1.4em" />
              <icon-checkbox-outline v-else size="1.4em" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="$t('activate.company')" />
            </v-list-item-content>
          </v-list-item>
        </template>
      </module-actions>
    </template>
  </data-table>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import CompaniesMixin from "@/modules/companies/mixins/CompaniesMixin";
import ActiveIcon from "@/components/common/ActiveIcon.vue";
import CompaniesForm from "@/modules/companies/components/Form.vue";
import type { DataTableHeader } from "vuetify";
import DocIdViewer from "@/components/common/DocIdViewer.vue";
import { AppModule } from "@/store/app";
import type { CompanyData } from "@planetadeleste/vue-mc-gw";
import { isDevel } from "@/composables/debug";
import CompanyFiltersRow from "@/modules/companies/components/CompanyFiltersRow.vue";

@Component({
  components: {
    CompanyFiltersRow,
    ActiveIcon,
    CompaniesForm,
    DocIdViewer,
  },
})
export default class CompaniesList extends Mixins(CompaniesMixin) {
  isList = true;

  get isLocal() {
    return isDevel();
  }

  get items(): CompanyData[] {
    return this.obCollection.getModelList() as CompanyData[];
  }

  get company() {
    return AppModule.company;
  }

  onMounted() {
    const arHeaders: DataTableHeader[] = [
      { text: "doctype", value: "doc_type" },
      { text: "docid", value: "doc_id" },
    ];
    this.addDTHeaders(arHeaders);
    this.hideActionsHeader = !this.canDelete;

    this.index();
  }

  async setCompany(obCompany: CompanyData) {
    if (obCompany.id === this.company.id) {
      return;
    }

    await AppModule.pickCompany(obCompany);
    await AppModule.loadCategories();
  }
}
</script>
