<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import CompanyFilters from "@/modules/companies/components/CompanyFilters.vue";
import FiltersRowMixin from "@/mixins/FiltersRowMixin";
import InputFilters from "@/components/form/InputFilters.vue";
import SelectDocType from "@/components/form/fields/SelectDocType.vue";

@Component({
  components: { SelectDocType, InputFilters },
})
export default class CompanyFiltersRow extends Mixins(
  FiltersRowMixin,
  CompanyFilters
) {}
</script>

<template>
  <input-filters
    ref="inputFilters"
    v-model="obFilterData"
    :model-name="sModelFilterKey"
    :headers="headerValueList"
    @apply="applyFilters"
    @reset="resetFilters"
  >
    <template #name>
      <form-field-text
        v-model="obFilterData.name"
        @input:debounce="onUpdateFilters"
        :dense="true"
        :outlined="false"
        solo
        hide-label
      />
    </template>

    <template #doc_id>
      <form-field-text
        v-model="obFilterData.docid"
        @input:debounce="onUpdateFilters"
        :dense="true"
        :outlined="false"
        solo
        hide-label
      />
    </template>

    <template #doc_type>
      <select-doc-type
        v-model="obFilterData.doctype"
        :dense="true"
        :outlined="false"
        solo
        hide-label
        @change="onUpdateFilters"
      />
    </template>

    <template #actions>
      <div class="text-right">
        <v-btn text color="primary" @click="onResetFilters">
          <v-icon>mdi-find-replace</v-icon>
        </v-btn>
      </div>
    </template>
  </input-filters>
</template>
