<template>
  <v-chip label v-text="docId" />
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import masker from "vue-the-mask/src/masker";
import tokens from "vue-the-mask/src/tokens";
import { get, has } from "lodash";
// import { VueMaskFilter } from "v-mask";

@Component
export default class DocIdViewer extends Vue {
  obMasks: Record<string, string> = {
    rut: "##-######-###-#",
    ci: "#.###.###-#",
  };
  @Prop(String) readonly value!: string;
  @Prop(String) readonly type!: string;
  @Prop(Boolean) readonly rut!: boolean;
  @Prop(Boolean) readonly ci!: boolean;

  get docId() {
    let sMask = "";

    if (!this.value) {
      return "";
    }

    if (!has(this.obMasks, this.type)) {
      return this.value;
    }

    if (this.type) {
      sMask = get(this.obMasks, this.type);
    } else if (!this.rut && !this.ci) {
      sMask = this.value.length == 8 ? this.obMasks.ci : this.obMasks.rut;
    } else {
      sMask = get(this.obMasks, this.ci ? "ci" : "rut");
    }

    return masker(this.value, sMask, true, tokens);
  }
}
</script>
